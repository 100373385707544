import { Button, Dialog, DialogContent, DialogTitle, Grid, useTheme } from "@mui/material";
import _ from "lodash";
import { ReactNode } from "react";
import NotificationList from "../notification/NotificationList";
import { useTranslation } from "react-i18next";

interface ImsConfirmDialogProps {
  article: string;
  verb: string;
  word: string;
  setConfirmVisible: (confirm: boolean) => void;
  onConfirm: () => void;
  hint?: ReactNode;
}

const ImsConfirmDialog = (props: ImsConfirmDialogProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Dialog onClose={() => props.setConfirmVisible(false)} open={true}>
      <NotificationList isDialog />
      <DialogTitle>
        {t("messages.confirmationTitle", {
          word: props.word,
          verb: props.verb,
        })}
      </DialogTitle>

      <DialogContent>
        <div style={{ marginBottom: theme.spacing(2) }}>
          {t("messages.confirmation", { article: props.article, word: props.word, verb: props.verb })}
        </div>
        {props.hint && <div style={{ marginBottom: theme.spacing(2) }}>{props.hint}</div>}
        <Grid container justifyContent="flex-end">
          <Button
            color="secondary"
            variant="contained"
            onClick={() => props.setConfirmVisible(false)}
            style={{ margin: theme.spacing(0, 0, 2, 2) }}
          >
            {t("common.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.onConfirm();
              props.setConfirmVisible(false);
            }}
            style={{ margin: theme.spacing(0, 0, 2, 2) }}
          >
            {_.capitalize(props.verb)}
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
export default ImsConfirmDialog;
