import { connect, ConnectedProps } from "react-redux";
import { useParams } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { BonusPremiumDto, BonusPremiumStatus } from "../../store/models/bonusPremium/BonusPremiumDto";
import { CouponAdvantageType } from "../../store/models/bonusPremium/CouponAdvantageType";
import { PageViewMode } from "../../store/models/PageViewMode";
import MenuLayout from "../app/MenuLayout";
import BonusPremiumForm from "./BonusPremiumForm";
import { useTranslation } from "react-i18next";

const defaultBonusPremium: BonusPremiumDto = {
  unitNumber: "",
  premiumStatus: BonusPremiumStatus.ENABLED,
  couponDetails: {
    advantageType: CouponAdvantageType.EURO,
    advantageInfo: "",
    generatorPrefix: "",
  },
};

const BonusPremiumFormPage = (props: ThunkProps) => {
  const { t } = useTranslation();

  const { bonusPremiums = [], operationalUnits = [] } = props;
  const { viewMode, id } = useParams<"viewMode" | "id">();
  const bonusPremium = bonusPremiums.find((bonusPremium) => bonusPremium.premiumNumber === id);

  function getHeadline(id?: string, viewMode?: string): string {
    if (!id) {
      return t('bonusPremium.newPremium');
    }
    if (viewMode === PageViewMode.VIEW) return t('bonusPremium.viewPremium');
    return t('bonusPremium.editPremium');
  }
  

  return (
    <MenuLayout headline={getHeadline(id, viewMode)}>
      <BonusPremiumForm
        bonusPremium={bonusPremium || defaultBonusPremium}
        operationalUnits={operationalUnits}
        viewMode={viewMode as PageViewMode}
      />
    </MenuLayout>
  );
};


const mapStateToProps = (state: IStore) => ({
  bonusPremiums: state.bonusPremiums.bonusPremiums,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusPremiumFormPage);
