import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { UrlPathBonusViewMode } from "../../api/url";
import {
  thunkActivateBonusRule,
  thunkDeactivateBonusRule,
  thunkDeleteBonusRule,
} from "../../store/actions/BonusRuleActions";
import { canMaintainBonus } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { BonusRuleDto } from "../../store/models/bonusRule/BonusRuleDto";
import { BonusRuleStatus } from "../../store/models/bonusRule/BonusRuleStatus";
import { PageViewMode } from "../../store/models/PageViewMode";
import ImsConfirmDialog from "../atoms/ImsConfirmDialog";
import { getCurrentDateAsString } from "../atoms/Utils";
import { useTranslation } from "react-i18next";

const canDelete = (bonusRule: BonusRuleDto) => {
  const { validFrom, status } = bonusRule;
  if (!canMaintainBonus()) return false;
  if (validFrom && validFrom < getCurrentDateAsString()) return false;
  return status === BonusRuleStatus.DISABLED;
};

const canEdit = (bonusRule: BonusRuleDto) => {
  if (!canMaintainBonus()) return false;
  return true;
};

const canActivate = (bonusRule: BonusRuleDto) => {
  const { validTill, status } = bonusRule;
  if (!canMaintainBonus()) return false;
  if (validTill && validTill < getCurrentDateAsString()) return false;
  return status === BonusRuleStatus.DISABLED;
};

const canDeactivate = (bonusRule: BonusRuleDto) => {
  const { status } = bonusRule;
  if (!canMaintainBonus()) return false;
  return status === BonusRuleStatus.ENABLED;
};

interface BonusRuleMenuProps {
  bonusRule: BonusRuleDto;
}

const BonusRuleMenu = (props: BonusRuleMenuProps & ThunkProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };
  const canActivateRule = canActivate(props.bonusRule);
  const canDeactivateRule = canDeactivate(props.bonusRule);
  const canEditRule = canEdit(props.bonusRule);
  const canDeleteRule = canDelete(props.bonusRule);
  const { t } = useTranslation();

  return (
    <div>
      {confirmVisible && (
        <ImsConfirmDialog
          article="die"
          word="Bonusregel"
          verb="löschen"
          onConfirm={() => props.thunkDeleteBonusRule(props.bonusRule)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {canActivateRule && (
          <MenuItem
            onClick={(e) => {
              props.thunkActivateBonusRule(props.bonusRule);
              handleClose(e);
            }}
          >
            {t("bonusRuleMenu.activate")}
          </MenuItem>
        )}
        {canDeactivateRule && (
          <MenuItem
            onClick={(e) => {
              props.thunkDeactivateBonusRule(props.bonusRule);
              handleClose(e);
            }}
          >
            {t("bonusRuleMenu.deactivate")}
          </MenuItem>
        )}
        {canDeleteRule && (
          <MenuItem
            onClick={(e) => {
              setConfirmVisible(true);
              handleClose(e);
            }}
          >
            {t("bonusRuleMenu.delete")}
          </MenuItem>
        )}
        {canEditRule && (
          <MenuItem onClick={(e) => handleClose(e)}>
            <Link
              component={RouterLink}
              to={UrlPathBonusViewMode.replace(":id", props.bonusRule.ruleNumber).replace(
                ":viewMode",
                PageViewMode.EDIT
              )}
            >
              {t("bonusRuleMenu.edit")}
            </Link>
          </MenuItem>
        )}

        <MenuItem onClick={(e) => handleClose(e)}>
          <Link
            component={RouterLink}
            to={UrlPathBonusViewMode.replace(":id", props.bonusRule.ruleNumber).replace(":viewMode", PageViewMode.VIEW)}
          >
            {t("bonusRuleMenu.show")}
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
};

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ thunkDeleteBonusRule, thunkActivateBonusRule, thunkDeactivateBonusRule }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusRuleMenu);
