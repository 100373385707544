import { Box, Theme, Typography, useTheme } from "@mui/material";
import AvatarMenu from "./AvatarMenu";
import LanguageSwitcher from "./LanguageSwitcher";

// const TopNav = React.lazy(() =>
//   // @ts-ignore
//   // eslint-disable-next-line import/no-unresolved
//   import("parentAdminFrontend/topnav").catch(() => {
//     return {
//       default: () => (
//         <>
//           <LightTooltip title="Die Navigation ist vorübergehend nicht erreichbar.">
//             <Warning color="warning" fontSize="large" />
//           </LightTooltip>
//         </>
//       ),
//     };
//   })
// );

interface HeaderProps {
  headline: string;
  subHeadline?: string;
}

const Header = (props: HeaderProps) => {
  const theme = useTheme();
  const classes = styles(theme);
  return (
    <Box component="header" sx={classes.header}>
      <div style={{ display: "block" }}>
        <Typography variant="h1">{props.headline}</Typography>
        {props.subHeadline ? <Typography variant="h4">{props.subHeadline}</Typography> : null}
      </div>
      <Box component="div" sx={classes.headerMenu}>
        {/* <Help color="primary" style={{ marginRight: theme.spacing(3) }} /> */}
        <LanguageSwitcher />
        <span style={{ margin: "0 10px" }}></span>
        <AvatarMenu />
      </Box>
    </Box>
  );
};

const styles = (theme: Theme) => ({
  header: {
    padding: theme.spacing(3),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerMenu: {
    display: "flex",
    alignItems: "center",
  },
});

export default Header;
