import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  Paper,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathUser } from "../../api/url";
import { thunkCreateUser, thunkUpdateUser } from "../../store/actions/UserActions";
import { IStore } from "../../store/IStore";
import { UserDto, UserRole } from "../../store/models/user/UserDto";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { nameof, ValidationErrors } from "../atoms/Utils";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";
import { getUserRoleNames } from "./UserTable";
import { useValidateEmail, validatePassword } from "../atoms/FieldValidation";
import { FormPassword } from "../atoms/FormPassword";
import { useTranslation } from "react-i18next";

interface UserFormProps {
  user: UserDto;
}

const UserForm = (props: UserFormProps & ThunkProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const validateEmail = useValidateEmail();
  const { t } = useTranslation();

  const saveUser = async (userToSave: UserDto) => {
    let success;
    if (userToSave.userNumber) {
      success = await props.thunkUpdateUser(userToSave);
    } else {
      success = await props.thunkCreateUser(userToSave);
    }
    if (success) {
      setRedirect(true);
    }
  };

  const { user, operationalUnits } = props;
  if (redirect) {
    return <Navigate to={UrlPathUser} />;
  }
  const operationalUnitOptions = getOperationalUnitOptions(undefined, operationalUnits, theme, true, false);

  const validateForm = (values: UserDto) => {
    const errors: ValidationErrors<UserDto> = {};
    validateEmail(values.mailAddress, errors, "mailAddress");
    validatePassword(values.password, errors, "password");
    return errors;
  };
  return (
    <Form
      onSubmit={saveUser}
      initialValues={user}
      validate={validateForm}
      mutators={{
        handleRoleUpdate: ([args, checked], state, utils) => {
          const e = args as React.ChangeEvent<HTMLInputElement>;
          const updatedUserRole = e.target.name as UserRole;
          utils.changeValue(state, "userRoles", (value: UserRole[]) =>
            checked ? value.concat(updatedUserRole) : value.filter((userRole) => userRole !== updatedUserRole)
          );
        },
      }}
      render={({ handleSubmit, submitting, values, form }) => (
        <form onSubmit={handleSubmit}>
          <Paper
            style={{
              padding: theme.spacing(3),
              marginTop: theme.spacing(6),
            }}
          >
            <ImsPaperHead text={t("user.headline")} />
            <Grid container spacing={4}>
              <Grid item container spacing={2}>
                <Grid item md={12}>
                  <b>{t("user.data")}</b>
                </Grid>
                <Grid item sm={4}>
                  <FormInput
                    variant="outlined"
                    fullWidth={true}
                    type="text"
                    name={nameof<UserDto>("userName")}
                    label={t("user.username")}
                  />
                </Grid>

                <Grid item container spacing={2}>
                  <Grid item sm={2}>
                    <FormInput
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      name={nameof<UserDto>("firstName")}
                      label={t("user.firstName")}
                    />
                  </Grid>
                  <Grid item sm={2}>
                    <FormInput
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      name={nameof<UserDto>("lastName")}
                      label={t("user.lastName")}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item sm={4}>
                    <FormInput
                      variant="outlined"
                      fullWidth={true}
                      type="text"
                      name={nameof<UserDto>("mailAddress")}
                      label={t("user.mail")}
                    />
                  </Grid>
                </Grid>
                <Grid item container spacing={2}>
                  <Grid item sm={4}>
                    <FormPassword name={nameof<UserDto>("password")} label="Passwort" fullWidth />
                  </Grid>
                </Grid>
                <Grid container item spacing={3}>
                  <Grid item md={4}>
                    <FormSelect
                      label={t("user.operationalUnit")}
                      name={nameof<UserDto>("unitNumber")}
                      options={operationalUnitOptions}
                      fullWidth={true}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">
                    <b
                      style={{
                        marginBottom: theme.spacing(1),
                      }}
                    >
                      {t("user.role")}
                    </b>
                  </FormLabel>
                  <FormGroup>
                    {getUserRoleNames().map((userRoleName) => (
                      <FormControlLabel
                        key={userRoleName.key}
                        control={
                          <Checkbox
                            checked={values.userRoles.includes(userRoleName.key)}
                            onChange={form.mutators.handleRoleUpdate}
                            name={userRoleName.key}
                          />
                        }
                        label={userRoleName.value}
                      />
                    ))}
                  </FormGroup>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
          <Grid container justifyContent="flex-end">
            <Button
              color="secondary"
              variant="contained"
              onClick={() => setRedirect(true)}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              {t("common.back")}
            </Button>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              disabled={submitting}
              style={{ margin: theme.spacing(2, 0, 2, 2) }}
            >
              {t("common.save")}
            </Button>
          </Grid>
        </form>
      )}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkUpdateUser,
      thunkCreateUser,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(UserForm);
