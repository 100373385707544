import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { BonusPremiumDto, BonusPremiumStatus } from "../../store/models/bonusPremium/BonusPremiumDto";
import { DateTag } from "../atoms/DateTag";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import BonusPremiumTableMenu from "./BonusPremiumTableMenu";
import { useTranslation } from "react-i18next";

const useFormatAvailability = () => {
  const { t } = useTranslation();
  function formatAvailability(startDate?: string, endDate?: string) {
    return endDate ? (
      <>
        <DateTag date={startDate} />
        {" " + t("bonusPremium.table.to") + " "}
        <DateTag date={endDate} />
      </>
    ) : (
      <>
        {t("bonusPremium.table.from") + " "}
        <DateTag date={startDate} />
      </>
    );
  }
  return formatAvailability;
};

const BonusPremiumTable = (props: ThunkProps) => {
  const { t } = useTranslation();
  const formatAvailability = useFormatAvailability();
  const headCells: ImsHeadCell<BonusPremiumDto>[] = [
    { val: (val) => val.premiumName, node: t("bonusPremium.table.name") },
    { val: (val) => val.startDate, node: t("bonusPremium.table.availability") },
    { val: (val) => val.unitNumber, node: t("bonusPremium.table.operationalUnit") },
    { val: (val) => val.couponDetails.advantageType, node: t("bonusPremium.table.couponType") },
    { val: (val) => val.couponDetails.requiredBonusPoints, node: t("bonusPremium.table.bonusPoint") },
    { val: (val) => val.endDate, node: t("bonusPremium.table.validity") },
  ];

  const rows: ImsTableRow<BonusPremiumDto>[] = props.bonusPremiums.map((row) => ({
    value: row,
    nodes: [
      <b>{row.premiumName}</b>,
      formatAvailability(row.startDate, row.endDate),
      props.operationalUnits.find((ou) => ou.unitNumber === row.unitNumber)?.unitName,
      row.couponDetails.advantageInfo,
      row.couponDetails.requiredBonusPoints,
      <>
        {!row.couponDetails.expiryMonths
          ? t("bonusPremium.table.to") + " "
          : row.couponDetails.expiryMonths + " " + t("bonusPremium.table.month")}
        {!row.couponDetails.expiryMonths && <DateTag date={row.couponDetails.expiryDate} />}
      </>,
      <BonusPremiumTableMenu bonusPremium={row} />,
    ],
    disabled: row.premiumStatus === BonusPremiumStatus.DISABLED,
  }));
  return <ImsTable hasMenu={true} headCells={headCells} rows={rows} />;
};

const mapStateToProps = (state: IStore) => ({
  bonusPremiums: state.bonusPremiums.bonusPremiums,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusPremiumTable);
