import { Button, Grid, Paper, Tooltip, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathMain } from "../../api/url";
import { IStore } from "../../store/IStore";
import MenuLayout from "../app/MenuLayout";
import { FormRadioButton } from "../atoms/FormRadioButton";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { CashbackStatus } from "../../store/models/cashback/CashbackStatus";
import { CashbackDto } from "../../store/models/cashback/CashbackDto";
import { thunkGetCashback, thunkUpdateCashback } from "../../store/actions/CashbackActions";
import { FormSelect } from "../atoms/FormSelect";
import { ValidationErrors, nameof, useMountEffect } from "../atoms/Utils";
import { CashbackCurrency } from "../../store/models/cashback/CashbackCurrency";
import CashbackRuleTable from "./CashbackRuleTable";
import { Help } from "@mui/icons-material";
import { FormInput } from "../atoms/FormInput";
import { CashbackExecutionPeriod } from "../../store/models/cashback/CashbackExecutionPeriod";
import { canMaintainCashBack, canReadCashBack } from "../../store/actions/LoginActions";
import { useTranslation } from "react-i18next";

const useStatusOptions = () => {
  const { t } = useTranslation();

  return [
    { value: CashbackStatus.ENABLED, label: t("cashback.radioButtonLabelActivate") },
    { value: CashbackStatus.DISABLED, label: t("cashback.radioButtonLabelDeactivate") },
  ];
};

const useExecutionOptions = () => {
  const { t } = useTranslation();
  return [
    {
      value: CashbackExecutionPeriod.COLLECTION_PERIOD_BASED,
      label: t("cashback.radioButtonLabelCollectionPeriodBased"),
    },
  ];
};

const useCashbackCurrencyOptions = () => {
  const { t } = useTranslation();
  return [{ value: CashbackCurrency.TOTAL_REVENUE_EURO, label: t("cashback.cashbackRateCurrencyPlaceholder") }];
};

interface CashbackForm extends CashbackDto {
  cashbackCirclesFlatterned?: string[];
  cashbackBonusCurrency?: string;
}

const CashbackPage = (props: ThunkProps) => {
  const [redirect, setRedirect] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();
  const statusOptions = useStatusOptions();
  const executionOptions = useExecutionOptions();
  const cashbackCurrencyOptions = useCashbackCurrencyOptions();

  const readOnly = !canMaintainCashBack();

  useMountEffect(() => {
    canReadCashBack() && props.thunkGetCashback();
  });

  if (!props.cashback) {
    return null;
  }

  const circleOptions = props.circles.map((circle) => ({
    value: circle.circleNumber,
    label: circle.circleName,
  }));

  const toCashBackForm = (cashbackDto: CashbackDto): CashbackForm => {
    const cashbackForm = {
      ...cashbackDto,
      cashbackCirclesFlatterned: cashbackDto.cashbackCircles.map((c) => c.circleNumber),
      cashbackBonusCurrency: t("cashback.bonusCurrencyStatusBonusPoint"), // This is hard coded on purpose
      cashbackCurrency: CashbackCurrency.TOTAL_REVENUE_EURO, // This is hard coded on purpose
    };
    cashbackForm.cashbackRateRules = [...cashbackForm.cashbackRateRules];
    return cashbackForm;
  };

  const toCashbackDto = (cashbackForm: CashbackForm): CashbackDto => {
    const cashBackDto = { ...cashbackForm };
    cashBackDto.cashbackCircles =
      cashbackForm.cashbackCirclesFlatterned?.map((c) => ({
        circleNumber: c,
      })) ?? [];

    //Remove form specific data
    delete cashBackDto.cashbackCirclesFlatterned;
    delete cashBackDto.cashbackBonusCurrency;
    delete cashBackDto.validFrom;
    delete cashBackDto.validTill;
    return cashBackDto as CashbackDto;
  };

  const validateForm = (values: CashbackForm) => {
    const errors: ValidationErrors<CashbackForm> = {};
    const requiredText = "Pflichtfeld";
    if (!values.cashbackCirclesFlatterned?.length) {
      errors.cashbackCirclesFlatterned = requiredText;
    }
    if (isNaN(values.counterValue)) {
      errors.counterValue = requiredText;
    }
    return errors;
  };

  const initialValues: CashbackForm = toCashBackForm(props.cashback);

  if (redirect) {
    return <Navigate to={UrlPathMain} />;
  }

  return (
    <MenuLayout headline={t("cashback.pageTitle")}>
      <Form
        onSubmit={async (cashback: CashbackForm) => {
          const cashbackDto = toCashbackDto(cashback);
          await props.thunkUpdateCashback(cashbackDto);
        }}
        initialValues={initialValues}
        validate={validateForm}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text={t("cashback.generalSettingsHeadline")} />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item>
                      <FormRadioButton
                        label={t("cashback.cashbackStatusLabel")}
                        name="cashbackStatus"
                        value={values.cashbackStatus}
                        options={statusOptions}
                        readOnly={readOnly}
                        row={true}
                      />
                    </Grid>
                    <Grid item xs={12} mb={4}></Grid>
                    <Grid item>
                      <FormRadioButton
                        label={t("cashback.cashbackExecuteModeLabel")}
                        description={t("cashback.cashbackExecuteModeDescription")}
                        name={nameof<CashbackForm>("executionPeriod")}
                        value={values.executionPeriod}
                        options={executionOptions}
                        readOnly={readOnly}
                        row={true}
                      />
                    </Grid>
                    <Grid item xs={12} mb={4}></Grid>
                    <Grid item xs={12}>
                      <strong>{t("cashback.cirleLabel")}</strong>
                    </Grid>
                    <Grid item xs={12}>
                      <p>{t("cashback.cirleLabelDescription")}</p>
                    </Grid>
                    <Grid item md={8}>
                      <FormSelect
                        readOnly={readOnly}
                        fullWidth
                        multiple
                        name={nameof<CashbackForm>("cashbackCirclesFlatterned")}
                        label={t("cashback.cirleInput")}
                        options={circleOptions}
                        testId="circlesField"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text={t("cashback.cashbackRateHeadline")} />
                <Grid container>
                  <Grid item mt={2} mb={4}>
                    <strong>{t("cashback.cashbackRateHeadlineDescription")}</strong>
                  </Grid>
                </Grid>
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={12} mb={3}>
                      <b>{t("cashback.cashbackRateBonusCurrencyDescription")}</b>
                    </Grid>
                    <Grid item md={3}>
                      <FormSelect
                        readOnly={true}
                        options={cashbackCurrencyOptions}
                        name={nameof<CashbackForm>("cashbackCurrency")}
                        label={t("cashback.cashbackRateCurrencyInput")}
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={3}>
                      <Tooltip
                        title={t("cashback.cashbackRateCurrencyTooltip")}
                        placement="right-start"
                        style={{ marginTop: 16, marginLeft: 10 }}
                      >
                        <Help color="primary" />
                      </Tooltip>
                    </Grid>

                    <Grid item xs={12} mb={4}>
                      <CashbackRuleTable cashbackRateRuleList={values.cashbackRateRules} />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text={t("cashback.permissionHeadline")} />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={12} mb={3}>
                      <b>{t("cashback.bonusCurrencyLabel")}</b>
                    </Grid>
                    <Grid item>
                      <FormInput
                        readOnly={true}
                        type="text"
                        name={nameof<CashbackForm>("cashbackBonusCurrency")}
                        label={t("cashback.bonusCurrencyInput")}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12} mb={4}></Grid>
                    <Grid item md={12} mb={3}>
                      <b>{t("cashback.counterValueLabel")}</b>
                    </Grid>
                    <Grid item>
                      <FormInput
                        readOnly={readOnly}
                        multiline
                        type="text"
                        name={nameof<CashbackForm>("counterValue")}
                        label={t("cashback.counterValueInput")}
                        variant="outlined"
                        testId="counterValueField"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting || readOnly}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("common.save")}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </MenuLayout>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetCashback, thunkUpdateCashback }, dispatch);

const mapStateToProps = (state: IStore) => ({
  circles: state.circles.circles,
  cashback: state.cashback.cashback,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CashbackPage);
