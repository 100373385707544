import { Add, Brightness1, Search } from "@mui/icons-material";
import { Button, Grid, Link, Paper, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { UrlPathCustomerDetails, UrlPathRegistration } from "../../api/url";
import { thunkCustomerSearch } from "../../store/actions/CustomerCareActions";
import { canMaintainCustomers, canReadSuperUser } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { CustomerDto } from "../../store/models/customer/CustomerDto";
import { CustomerSearchDto } from "../../store/models/customer/CustomerSearchDto";
import { getCustomerStatusColor } from "../../store/models/customer/CustomerStatusDto";
import MenuLayout from "../app/MenuLayout";
import { DateTag } from "../atoms/DateTag";
import { FormInput } from "../atoms/FormInput";
import { COLOR_BLACK } from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import { nameof } from "../atoms/Utils";
import { useTranslation } from "react-i18next";

const CustomerCareSearchPage = (props: ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const headCells: ImsHeadCell<CustomerDto>[] = [
    { val: (val) => val.status, node: "" },
    { val: (val) => val.externalCustomerId, node: t("customerCareSearchPage.headerExternalCustomerId") },
    { val: (val) => val.firstName, node: t("customerCareSearchPage.headerFirstName") },
    { val: (val) => val.lastName, node: t("customerCareSearchPage.headerLastname") },
    { val: (val) => val.mailAddress, node: t("customerCareSearchPage.headerMailAddress") },
    { val: (val) => val.dateOfBirth, node: t("customerCareSearchPage.headerDateOfBirth") },
    { val: (val) => val.address?.streetName, node: t("customerCareSearchPage.headerStreetName") },
    { val: (val) => val.address?.zipCode, node: t("customerCareSearchPage.headerZipCode") },
    { val: (val) => val.address?.cityName, node: t("customerCareSearchPage.headerCityName") },
  ];

  const rows: ImsTableRow<CustomerDto>[] | undefined = props.customers?.map((row) => ({
    value: row,
    nodes: [
      <Brightness1
        style={{
          fill: getCustomerStatusColor(row.status),
          paddingTop: 4,
        }}
      />,
      <Link component={RouterLink} to={UrlPathCustomerDetails.replace(":id", row.customerNumber)}>
        {row.externalCustomerId}
      </Link>,
      <b>{row.firstName}</b>,
      <b>{row.lastName}</b>,
      <a style={{ color: COLOR_BLACK, textDecoration: "none" }} href={`mailto:${row.mailAddress}`}>
        {row.mailAddress}
      </a>,
      <DateTag date={row.dateOfBirth} />,
      row.address && row.address.streetName && row.address.houseNumber
        ? row.address.streetName + " " + row.address.houseNumber
        : "",
      row.address ? row.address.zipCode : "",
      row.address ? row.address.cityName : "",
    ],
  }));

  return (
    <MenuLayout headline={t("customerCareSearchPage.title")}>
      <Form
        onSubmit={props.thunkCustomerSearch}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              {canMaintainCustomers() && (
                <Grid container justifyContent="flex-end">
                  <Link component={RouterLink} to={UrlPathRegistration}>
                    <Button color="primary" variant="contained">
                      <Add />
                      {t("customerCareSearchPage.buttonNewMember")}
                    </Button>
                  </Link>
                </Grid>
              )}
              <Paper
                style={{
                  padding: theme.spacing(3, 3, 6, 3),
                  margin: theme.spacing(6, 0, 3, 0),
                }}
              >
                <ImsPaperHead text={t("customerCareSearchPage.headline")} />
                <Grid container spacing={4}>
                  <Grid container spacing={2} item>
                    <Grid container spacing={2} item>
                      <Grid item md={12}>
                        <b>{t("customerCareSearchPage.labelName")}</b>
                      </Grid>
                      <Grid item md={2}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("firstName")}
                          label={t("customerCareSearchPage.firstName")}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={2}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("lastName")}
                          label={t("customerCareSearchPage.lastName")}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={3}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("mailAddress")}
                          label={t("customerCareSearchPage.email")}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} item>
                      <Grid item md={12}>
                        <b>{t("customerCareSearchPage.labelCustomerNumber")}</b>
                      </Grid>
                      <Grid item md={canReadSuperUser() ? 4 : 7}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("externalCustomerId")}
                          label={t("customerCareSearchPage.externalCustomerId")}
                          variant="outlined"
                        />
                      </Grid>
                      {canReadSuperUser() && (
                        <Grid item md={3}>
                          <FormInput
                            type="text"
                            fullWidth={true}
                            name={nameof<CustomerSearchDto>("customerNumber")}
                            label={t("customerCareSearchPage.customerNumber")}
                            variant="outlined"
                          />
                        </Grid>
                      )}
                      <Grid item md={5} />
                    </Grid>

                    <Grid container spacing={2} item>
                      <Grid item md={12}>
                        <b>{t("customerCareSearchPage.labelPassID")}</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("externalIdentificationNumber")}
                          label={t("customerCareSearchPage.externalIdentificationNumber")}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={2} item>
                      <Grid item md={12}>
                        <b>{t("customerCareSearchPage.labelAddress")}</b>
                      </Grid>
                      <Grid item md={5}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("streetName")}
                          label={t("customerCareSearchPage.streetName")}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={2}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("houseNumber")}
                          label={t("customerCareSearchPage.houseNumber")}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={5} />

                      <Grid item md={2}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("zipCode")}
                          label={t("customerCareSearchPage.zipCode")}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item md={5}>
                        <FormInput
                          type="text"
                          fullWidth={true}
                          name={nameof<CustomerSearchDto>("cityName")}
                          label={t("customerCareSearchPage.cityName")}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container style={{ marginBottom: theme.spacing(3) }} justifyContent="flex-end">
                <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                  <Search />
                  {t("customerCareSearchPage.buttonSearch")}
                </Button>
              </Grid>
            </form>
          );
        }}
      />

      {rows &&
        (rows.length ? (
          <ImsTable hasMenu={false} headCells={headCells} rows={rows} sortColumnIndex={1} />
        ) : (
          <p>{t("customerCareSearchPage.noResults")}</p>
        ))}
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  customers: state.customerCare.customers,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ thunkCustomerSearch }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(CustomerCareSearchPage);
