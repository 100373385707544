import { ConfigGroupKey } from "../config/ConfigGroupKey";
import { BonusRuleStatus } from "./BonusRuleStatus";
import { BonusRuleType } from "./BonusRuleType";
import { BonusRuleValueType } from "./BonusRuleValueType";
import { CustomerInteractionType } from "./CustomerInteractionType";
import { PurchaseTotalCondition } from "./PurchaseTotalCondition";
import { FrequencyMode } from "./FrequencyMode";
import { RuleRoundingMode } from "./RuleRoundingMode";
import { useTranslation } from "react-i18next";

export interface BonusRuleDto {
  ruleNumber: string;
  ruleName: string;
  unitNumbers: string[];
  value: string;
  valueType: BonusRuleValueType;
  budget?: number;
  status: BonusRuleStatus;
  type: BonusRuleType;
  validFrom?: string;
  validTill?: string;
  customerInteractionType?: CustomerInteractionType | string;
  daysToDelay?: number;
  ruleRoundingMode?: RuleRoundingMode;
  ruleFilters: FilterDto[];
  transactionSumValue?: string;
  transactionSumType?: PurchaseTotalCondition;
  frequency?: string;
  frequencyMode?: FrequencyMode;
  frequencyMax?: string;
  promotionText?: string;
}

export interface FilterDto {
  ruleFilterNumber?: string;
  ruleFilterName?: string;
  ruleFilterType?: ConfigGroupKey;
  ruleFilterApplyType: RuleFilterApplyType;
  ruleFilterPropertyName?: ReceiptDetailsFilterType | RuleFilterCustomerPropertyType | RuleFilterDatePropertyType;
  ruleFilterPropertyGroupNumber?: string;
  ruleFilterValues: string[];
  validFrom?: string;
  validTill?: string;
}

export enum RuleFilterCustomerPropertyType {
  CIRCLE_NUMBER = "CIRCLE_NUMBER",
  GENDER = "GENDER",
  EXTERNAL_CUSTOMER_NUMBER = "EXTERNAL_CUSTOMER_NUMBER",
  CUSTOMER_NUMBER = "CUSTOMER_NUMBER",
  AGE = "AGE",
}

export enum RuleFilterDatePropertyType {
  DAY_AND_TIME_RANGE = "DAY_AND_TIME_RANGE",
  DATE_RANGE = "DATE_RANGE",
}

export enum RuleFilterApplyType {
  INCLUDE = "INCLUDE",
  EXCLUDE = "EXCLUDE",
}

export const useRuleFilterApplyTypeOptions = () => {
  const { t } = useTranslation();

  return [
    {
      label: t("bonusRuleFilter.includeArticle"),
      value: RuleFilterApplyType.INCLUDE,
    },
    {
      label: t("bonusRuleFilter.excludeArticle"),
      value: RuleFilterApplyType.EXCLUDE,
    },
  ];
};

export enum ReceiptDetailsFilterType {
  PRODUCT_GROUP_NUMBER = "PRODUCT_GROUP_NUMBER",
  PRODUCT_GROUP_DESC = "PRODUCT_GROUP_DESC",
  SUB_GROUP_NUMBER = "SUB_GROUP_NUMBER",
  SUB_GROUP_DESC = "SUB_GROUP_DESC",
  SALES_AREA_NUMBER = "SALES_AREA_NUMBER",
  SALES_AREA_DESC = "SALES_AREA_DESC",
  SUPPLIER_NUMBER = "SUPPLIER_NUMBER",
  SUPPLIER_DESC = "SUPPLIER_DESC",
  SUPPLIER_ITEM_TEXT = "SUPPLIER_ITEM_TEXT",
  REF_NUMBER = "REF_NUMBER",
  EAN = "EAN",
}
