import { Grid, Link, Typography, useTheme } from "@mui/material";
import { formatEuro, formatGermanDate } from "../atoms/Utils";
import { PaymentModel } from "./PaymentModel";
import { useTranslation } from "react-i18next";

function getAmountCurrentYear(payments: PaymentModel[]) {
  return formatEuro(
    payments
      .filter((payment) => payment.year === new Date().getFullYear())
      .reduce((prev, cur) => prev + cur.displayedPaymentAmount, 0)
  );
}

interface PaymentProps {
  openDialog: () => void;
  payments: PaymentModel[];
}
const Payment = (props: PaymentProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { payments, openDialog } = props;

  const lastThreePayments = payments.slice(0, 3);
  return (
    <Grid item md container direction="column" justifyContent="space-between">
      <Grid container>
        <Grid
          container
          style={{
            padding: theme.spacing(3, 0),
          }}
          justifyContent="center"
        >
          <b>{t("customerCareDetailsPage.keyFiguresLabelRevenue")}</b>
        </Grid>
        <Grid container justifyContent="center">
          <Typography variant="caption">{t("customerCareDetailsPage.keyFiguresLabelThisYear")}</Typography>
        </Grid>
        <Grid
          container
          style={{
            fontSize: 42,
            marginBottom: theme.spacing(2),
          }}
          justifyContent="center"
        >
          {getAmountCurrentYear(payments)}
        </Grid>

        {lastThreePayments.map((payment) => (
          <Grid key={payment.interactionNumber} container spacing={1} style={{ padding: theme.spacing(0, 3) }}>
            <Grid item md>
              {formatGermanDate(payment.interactionTimestamp)}
            </Grid>
            <Grid item md container justifyContent="flex-end">
              {formatEuro(payment.displayedPaymentAmount)}
            </Grid>
          </Grid>
        ))}
      </Grid>
      {payments.length > 0 && (
        <Grid
          container
          justifyContent="flex-end"
          style={{
            padding: theme.spacing(0, 2, 0, 0),
          }}
        >
          <Link style={{ cursor: "pointer" }} onClick={openDialog}>
            <Typography variant="button">mehr anzeigen</Typography>
          </Link>
        </Grid>
      )}
    </Grid>
  );
};

export default Payment;
