import { Button, Grid, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import { AnyObject, Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathBonusPremium } from "../../api/url";
import { thunkCreateBonusPremium, thunkUpdateBonusPremium } from "../../store/actions/BonusPremiumActions";
import { IStore } from "../../store/IStore";
import {
  BonusPremiumCouponDetailsDto,
  BonusPremiumDto,
  BonusPremiumStatus,
} from "../../store/models/bonusPremium/BonusPremiumDto";
import { CouponAdvantageType } from "../../store/models/bonusPremium/CouponAdvantageType";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { PageViewMode } from "../../store/models/PageViewMode";
import { FormCheckbox } from "../atoms/FormCheckbox";
import { FormDate } from "../atoms/FormDate";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import { FormSelect } from "../atoms/FormSelect";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { getTomorrow, isBlank, isPositiveInteger, nameof, nameof2 } from "../atoms/Utils";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";
import { useTranslation } from "react-i18next";

const useAdvantageTypeOptions = () => {
  const { t } = useTranslation();

  return [
    { value: CouponAdvantageType.EURO, label: t("bonusPremium.couponAdvantageType.euro") },
    { value: CouponAdvantageType.PERCENT, label: t("bonusPremium.couponAdvantageType.percent") },
    { value: CouponAdvantageType.OFFER, label: t("bonusPremium.couponAdvantageType.offer") },
  ];
};

const useCouponValidityTypeOptions = () => {
  const { t } = useTranslation();

  return [
    {
      label: t("bonusPremium.expireType.date"),
      value: "invalidAt",
    },
    {
      label: t("bonusPremium.expireType.period"),
      value: "invalidPeriod",
    },
  ];
};

interface BonusPremiumFormProps extends ThunkProps {
  bonusPremium: BonusPremiumDto;
  operationalUnits: OperationalUnitDto[];
  viewMode: PageViewMode;
}

const BonusPremiumForm = (props: BonusPremiumFormProps) => {
  const theme = useTheme();
  const [redirect, setRedirect] = useState(false);
  const { t } = useTranslation();
  const advantageTypeOptions = useAdvantageTypeOptions();
  const couponValidityTypeOptions = useCouponValidityTypeOptions();
  const validateForm = useValidateForm();

  const saveBonusPremium = async (formModel: BonusPremiumFormModel) => {
    let success;
    const bonusPremium = mapFormModelToBonusPremium(formModel);
    if (formModel.premiumNumber) {
      success = await props.thunkUpdateBonusPremium(bonusPremium);
    } else {
      success = await props.thunkCreateBonusPremium(bonusPremium);
    }
    if (success) {
      setRedirect(true);
    }
  };

  const operationalUnitOptions = getOperationalUnitOptions(undefined, props.operationalUnits, theme, true, false);

  if (redirect) {
    return <Navigate to={UrlPathBonusPremium} />;
  }
  const formModel = mapBonusPremiumToFormModel(props.bonusPremium);

  const rootOperationalUnit = props.operationalUnits.find((operationalUnit) => !operationalUnit.parentUnitNumber);

  formModel.unitNumber = formModel.unitNumber || rootOperationalUnit?.unitNumber || "";

  const readOnly = props.viewMode === PageViewMode.VIEW;

  return (
    <>
      <Form
        onSubmit={saveBonusPremium}
        initialValues={formModel}
        validate={validateForm}
        render={({ handleSubmit, submitting, values }) => {
          const isInvalidAfterPeriod = (couponValidityType: string) => {
            if (couponValidityType === "invalidPeriod") {
              delete values.couponDetails.expiryDate;
              return true;
            } else {
              delete values.couponDetails.expiryMonths;
              return false;
            }
          };
          return (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={6}>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      type="text"
                      name={nameof<BonusPremiumFormModel>("premiumName")}
                      label={t("bonusPremium.couponName")}
                      variant="standard"
                      fullWidth={true}
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
                <Grid container item>
                  <Grid item md={12}>
                    <FormInput
                      type="textarea"
                      fullWidth={true}
                      name={nameof<BonusPremiumFormModel>("premiumDescription")}
                      label={t("bonusPremium.couponDescription")}
                      variant="standard"
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                }}
              >
                <ImsPaperHead text={t("bonusPremium.couponHeadline")} />
                <Grid container item spacing={4}>
                  <Grid item md={12}>
                    <b>{t("bonusPremium.couponDisplayNameLabel")}</b>
                  </Grid>
                  <Grid item md={5} style={{ marginBottom: theme.spacing(2) }}>
                    <FormInput
                      type="text"
                      name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                        "couponDetails",
                        "advantageInfo"
                      )}
                      label={t("bonusPremium.couponDisplayNamePlaceholder")}
                      fullWidth={true}
                      variant="outlined"
                      tooltip={t("bonusPremium.couponDisplayNameTooltip")}
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        value={values.couponDetails.advantageType}
                        name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                          "couponDetails",
                          "advantageType"
                        )}
                        label={t("bonusPremium.couponTypeLabel")}
                        options={advantageTypeOptions}
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>

                  {values.couponDetails.advantageType === CouponAdvantageType.EURO && (
                    <Grid container item spacing={4}>
                      <Grid item md={12}>
                        <b>{t("bonusPremium.amountMoneyInEuroLabel")}</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                            "couponDetails",
                            "advantageValue"
                          )}
                          label={t("bonusPremium.amountMoneyInEuroPlaceholder")}
                          fullWidth={true}
                          variant="outlined"
                          readOnly={readOnly}
                        />
                      </Grid>
                    </Grid>
                  )}
                  {values.couponDetails.advantageType === CouponAdvantageType.PERCENT && (
                    <Grid container item spacing={4}>
                      <Grid item md={12}>
                        <b>{t("bonusPremium.discountInPercentLabel")}</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                            "couponDetails",
                            "advantageValue"
                          )}
                          label={t("bonusPremium.discountInPercentPlaceholder")}
                          fullWidth={true}
                          variant="outlined"
                          readOnly={readOnly}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item spacing={4}>
                    <Grid item md={12}>
                      <b>{t("bonusPremium.requiredBonusPointsLabel")}</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormInput
                        type="number"
                        name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                          "couponDetails",
                          "requiredBonusPoints"
                        )}
                        label={t("bonusPremium.requiredBonusPointsPlaceholder")}
                        fullWidth={true}
                        variant="outlined"
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={4}>
                    <Grid item md={12}>
                      <b>{t("bonusPremium.maxCouponsLabel")}</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormInput
                        type="number"
                        name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                          "couponDetails",
                          "maxCoupons"
                        )}
                        label={t("bonusPremium.maxCouponsPlaceholder")}
                        fullWidth={true}
                        variant="outlined"
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item>
                    <Grid item md={6}>
                      <FormRadioButton
                        name={nameof<BonusPremiumFormModel>("couponValidityType")}
                        value={values.couponValidityType}
                        label={t("bonusPremium.couponValidityTypeLabel")}
                        options={couponValidityTypeOptions}
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                  {isInvalidAfterPeriod(values.couponValidityType) ? (
                    <Grid container item>
                      <Grid item md={12} style={{ marginBottom: theme.spacing(2) }}>
                        {t("bonusPremium.expiryDateLabel")}
                      </Grid>
                      <Grid item md={4}>
                        <FormInput
                          type="number"
                          name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                            "couponDetails",
                            "expiryMonths"
                          )}
                          label={t("bonusPremium.expiryMonthsPlaceholder")}
                          readOnly={readOnly}
                        />
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container item>
                      <Grid item md={12}>
                        <b>{t("bonusPremium.expiryDateLabel")}</b>
                      </Grid>
                      <Grid item md={4}>
                        <FormDate
                          name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                            "couponDetails",
                            "expiryDate"
                          )}
                          label={t("bonusPremium.expiryDatePlaceholder")}
                          prefix={t("bonusPremium.to")}
                          minDate={getTomorrow()}
                          readOnly={readOnly}
                        />
                      </Grid>
                    </Grid>
                  )}
                  <Grid container item>
                    <Grid item md={4}>
                      <b>{t("bonusPremium.unitNumberLabel")}</b>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={3}>
                    <Grid item md={4}>
                      <FormSelect
                        label={t("bonusPremium.unitNumberPlaceholder")}
                        name={nameof<BonusPremiumFormModel>("unitNumber")}
                        options={operationalUnitOptions}
                        fullWidth={true}
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>

                  <Grid container item spacing={2}>
                    <Grid item md={12}>
                      <b>{t("bonusPremium.couponId")}</b>
                    </Grid>
                    <Grid item md={4}>
                      <FormInput
                        type="text"
                        name={nameof2<BonusPremiumFormModel, BonusPremiumCouponDetailsDto>(
                          "couponDetails",
                          "generatorPrefix"
                        )}
                        label={t("bonusPremium.prefix")}
                        fullWidth={true}
                        variant="outlined"
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(8),
                  marginBottom: theme.spacing(3),
                }}
              >
                <ImsPaperHead text={t("bonusPremium.distributeHeadline")} />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={4}>
                      <FormDate
                        name={nameof<BonusPremiumFormModel>("startDate")}
                        label={t("bonusPremium.date")}
                        prefix={t("bonusPremium.startDatePrefix")}
                        testId="dt-sd"
                        readOnly={readOnly}
                      />
                    </Grid>
                    <Grid item md={4}>
                      <FormDate
                        name={nameof<BonusPremiumFormModel>("endDate")}
                        label={t("bonusPremium.date")}
                        prefix={t("bonusPremium.endDatePrefix")}
                        helperText="Optional"
                        testId="dt-ed"
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>

              {!values.premiumNumber && (
                <Grid container item justifyContent="flex-end">
                  <FormCheckbox
                    name={nameof<BonusPremiumFormModel>("active")}
                    label={t("bonusPremium.activateCouponDirectly")}
                    tooltip={t("bonusPremium.activateCouponDirectlyTooltip")}
                    readOnly={readOnly}
                  />
                </Grid>
              )}
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("common.cancel")}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  data-testid="button-save"
                  variant="contained"
                  disabled={submitting || readOnly}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {values.premiumNumber ? t("bonusPremium.saveCoupon") : t("bonusPremium.createCoupon")}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </>
  );
};

interface BonusPremiumFormModel extends BonusPremiumDto {
  couponValidityType: "invalidAt" | "invalidPeriod";
  active: boolean;
}

const mapBonusPremiumToFormModel = (premium: BonusPremiumDto): BonusPremiumFormModel => {
  return {
    ...premium,
    couponValidityType:
      premium.couponDetails.expiryMonths && premium.couponDetails.expiryMonths > 0 ? "invalidPeriod" : "invalidAt",
    active: premium.premiumStatus === "ENABLED",
  };
};

const mapFormModelToBonusPremium = (premiumForm: BonusPremiumFormModel): BonusPremiumDto => {
  const premium = {
    ...premiumForm,
  };

  premium.couponDetails.generatorNumber = "DEFAULT-CODE-GENERATOR";
  premium.premiumStatus = premiumForm.active ? BonusPremiumStatus.ENABLED : BonusPremiumStatus.DISABLED;
  return premium;
};
const useValidateForm = () => {
  const { t } = useTranslation();
  const validateCouponDetails = useValidateCouponDetails();
  const validateForm = (values: BonusPremiumFormModel) => {
    const errors: AnyObject = {};
    if (isBlank(values.premiumName)) {
      errors.premiumName = t("bonusPremium.couponNameRequiredHelper");
    }

    if (isBlank(values.unitNumber)) {
      errors.unitNumber = t("messages.validation.required");
    }

    if (values.startDate && values.endDate && values.endDate < values.startDate) {
      errors.endDate = t("bonusPremium.invalidDatedHelper");
    }

    return validateCouponDetails(values, errors);
  };
  return validateForm;
};

const useValidateCouponDetails = () => {
  const { t } = useTranslation();

  function validateCouponDetails(values: BonusPremiumFormModel, errors: AnyObject) {
    errors.couponDetails = {};
    if (isBlank(values.couponDetails.advantageInfo)) {
      errors.couponDetails.advantageInfo = t("bonusPremium.couponNameRequiredHelper");
    }
    if (values.couponDetails.advantageType !== CouponAdvantageType.OFFER && !values.couponDetails.advantageValue) {
      errors.couponDetails.advantageValue = t("bonusPremium.discountInPercentRequireHelper");
    }
    if (values.couponValidityType === "invalidAt" && !values.couponDetails.expiryDate) {
      errors.couponDetails.expiryDate = t("messages.validation.required");
    }
    if (values.couponValidityType === "invalidPeriod") {
      if (!values.couponDetails.expiryMonths) {
        errors.couponDetails.expiryMonths = t("messages.validation.required");
      } else if (!isPositiveInteger(values.couponDetails.expiryMonths)) {
        errors.couponDetails.expiryMonths = t("bonusPremium.positiveIntegerOnly");
      }
    }
    if (isBlank(values.couponDetails.generatorPrefix)) {
      errors.couponDetails.generatorPrefix = t("messages.validation.required");
    } else if (values.couponDetails.generatorPrefix.length > 6) {
      errors.couponDetails.generatorPrefix = t("bonusPremium.generatorPrefixMaxDigitHelper", { digit: 6 });
    }

    if (values.couponDetails.expiryDate && !values.endDate) {
      errors.endDate = t("bonusPremium.requiredIfHaveExpiryDate");
    }

    if (values.couponDetails.expiryDate && values.endDate && values.couponDetails.expiryDate < values.endDate) {
      errors.couponDetails.expiryDate = t("bonusPremium.expiryDateMustNotBeforeEndDate");
    }
    return errors;
  }

  return validateCouponDetails;
};

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCreateBonusPremium,
      thunkUpdateBonusPremium,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusPremiumForm);
