import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathUserEdit } from "../../api/url";
import { canMaintainUsers } from "../../store/actions/LoginActions";
import { thunkActivateUser, thunkDeactivateUser, thunkDeleteUser } from "../../store/actions/UserActions";
import { IStore } from "../../store/IStore";
import { UserDto } from "../../store/models/user/UserDto";
import ImsConfirmDialog from "../atoms/ImsConfirmDialog";
import { UserStatus } from "./UserStatus";
import { useTranslation } from "react-i18next";

const canActivateUser = (user: UserDto) => {
  if (!canMaintainUsers()) return false;
  return user.status === UserStatus.INACTIVE;
};

const canDeactivateUser = (user: UserDto) => {
  if (!canMaintainUsers()) return false;
  return user.status === UserStatus.ACTIVE;
};

interface UserTableMenuProps {
  user: UserDto;
}

function UserTableMenu(props: UserTableMenuProps & ThunkProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();
  const [confirmVisible, setConfirmVisible] = useState<boolean>();
  const { t } = useTranslation();

  if (!canMaintainUsers()) return null;
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(undefined);
    event.stopPropagation();
  };

  const canActivate = canActivateUser(props.user);
  const canDeactivate = canDeactivateUser(props.user);

  return (
    <div>
      {confirmVisible && (
        <ImsConfirmDialog
          article={t("user.the")}
          word={t("user.admin")}
          verb={t("common.delete")}
          onConfirm={() => props.thunkDeleteUser(props.user)}
          setConfirmVisible={setConfirmVisible}
        />
      )}
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu id="long-menu" anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {canActivate && (
          <MenuItem
            onClick={(e) => {
              props.thunkActivateUser(props.user);
              handleClose(e);
            }}
          >
            {t("common.activate")}
          </MenuItem>
        )}
        {canDeactivate && (
          <MenuItem
            onClick={(e) => {
              props.thunkDeactivateUser(props.user);
              handleClose(e);
            }}
          >
            {t("common.deactivate")}
          </MenuItem>
        )}
        <MenuItem onClick={handleClose}>
          <Link component={RouterLink} to={UrlPathUserEdit.replace(":id", props.user.userNumber!)}>
            {t("common.edit")}
          </Link>
        </MenuItem>
        <MenuItem
          onClick={(e) => {
            setConfirmVisible(true);
            handleClose(e);
          }}
        >
          {t("common.delete")}
        </MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = (_state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkActivateUser,
      thunkDeactivateUser,
      thunkDeleteUser,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(UserTableMenu);
