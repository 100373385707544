import { Button, Dialog, DialogActions, DialogTitle, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkCancelCustomerMembership } from "../../store/actions/MembershipCancellationActions";
import { IStore } from "../../store/IStore";
import { MembershipCancellationDto } from "../../store/models/customer/MembershipCancellationDto";
import { FormDate } from "../atoms/FormDate";
import { COLOR_GRAY_1, COLOR_WHITE, KNISTR_RED } from "../atoms/ImsMaterialTheme";
import { getTomorrowDateAsString, nameof, ValidationErrors } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";
import { useTranslation } from "react-i18next";

interface MembershipCancellationDialogProps {
  setMembershipCancellationDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const MembershipCancellationDialog = (props: MembershipCancellationDialogProps & ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const cancelCustomerMembership = async (membershipCancellation: MembershipCancellationDto) => {
    if (!props.customer) return;
    membershipCancellation.customerNumber = props.customer.customerNumber;
    membershipCancellation && (await props.thunkCancelCustomerMembership(membershipCancellation));
    props.setMembershipCancellationDialogVisible(false);
  };

  const validateForm = (membershipCancellation: MembershipCancellationDto) => {
    const errors: ValidationErrors<MembershipCancellationDto> = {};
    if (!membershipCancellation.cancellationDate) {
      errors.cancellationDate = "Pflichtfeld";
    }
    return errors;
  };

  return (
    <Dialog onClose={() => props.setMembershipCancellationDialogVisible(false)} open={true}>
      <NotificationList isDialog />
      <Form
        onSubmit={cancelCustomerMembership}
        initialValues={{
          cancellationDate: getTomorrowDateAsString(),
        }}
        validate={validateForm}
        render={({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>{t("membershipCancelDialog.title")}</DialogTitle>
            <Divider
              style={{
                color: COLOR_GRAY_1,
                height: "2px",
              }}
              light={true}
            />
            <Grid container>
              <Grid
                item
                style={{
                  padding: theme.spacing(4, 2, 2, 2),
                }}
              >
                <p>{t("membershipCancelDialog.confirm1")}</p>
              </Grid>
              <Grid
                item
                style={{
                  padding: theme.spacing(0, 0, 4, 2),
                }}
              >
                {
                  <FormDate
                    name={nameof<MembershipCancellationDto>("cancellationDate")}
                    label={t("membershipCancelDialog.cancellationDate")}
                    fullWidth={true}
                  />
                }
              </Grid>
              <Grid
                item
                style={{
                  padding: theme.spacing(0, 0, 0, 2),
                }}
              >
                <p>{t("membershipCancelDialog.confirm2")}</p>
              </Grid>
            </Grid>
            <DialogActions>
              <Button
                onClick={() => props.setMembershipCancellationDialogVisible(false)}
                variant="contained"
                style={{
                  backgroundColor: theme.palette.primary.main,
                  color: theme.palette.primary.contrastText,
                }}
              >
                {t("common.back")}
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={submitting}
                style={{
                  backgroundColor: KNISTR_RED,
                  color: COLOR_WHITE,
                }}
              >
                {t("membershipCancelDialog.buttonCancelMember")}
              </Button>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCancelCustomerMembership,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(MembershipCancellationDialog);
