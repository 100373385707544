import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { AdjustmentDto } from "../models/adjustment/AdjustmentDto";
import { reloadCustomerHistory } from "./HistoryActions";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";
import i18n from "i18next";

export const thunkCreateAdjustmentEarn =
  (adjustment: AdjustmentDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createAdjustmentEarn(adjustment);
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.adjustmentActions.success.earnAdjustmentCreate")));
      reloadCustomerHistory(dispatch, adjustment.customerNumber);
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification(i18n.t("messages.adjustmentActions.error.earnAdjustmentCreate"), e));
      return false;
    }
  };

export const thunkCreateAdjustmentBurn =
  (adjustment: AdjustmentDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createAdjustmentBurn(adjustment);
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.adjustmentActions.success.burnAdjustmentCreate")));
      reloadCustomerHistory(dispatch, adjustment.customerNumber);
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification(i18n.t("messages.adjustmentActions.error.burnAdjustmentCreate"), e));
      return false;
    }
  };
