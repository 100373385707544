import { Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { UrlPathUserCreate } from "../../api/url";
import { canMaintainUsers } from "../../store/actions/LoginActions";
import MenuLayout from "../app/MenuLayout";
import UserTable from "./UserTable";
import { useTranslation } from "react-i18next";

export const UserTablePage = () => {
  const { t } = useTranslation();

  return (
    <MenuLayout headline={t("user.title")}>
      <Grid container spacing={2}>
        {canMaintainUsers() && (
          <Grid container item justifyContent="flex-end">
            <Button component={Link} to={UrlPathUserCreate} startIcon="+" variant="contained" color="primary">
              {t("user.createUser")}
            </Button>
          </Grid>
        )}
        <Grid item md={12}>
          <UserTable />
        </Grid>
      </Grid>
    </MenuLayout>
  );
};
