import { Add, KeyboardArrowDown, KeyboardArrowRight } from "@mui/icons-material";
import { TreeView } from "@mui/x-tree-view";
import { Autocomplete, Button, Grid, Switch, TextField, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { UrlPathOperationalUnit, UrlPathOperationalUnitCreate } from "../../api/url";
import { canMaintainOperationalUnits } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import MenuLayout from "../app/MenuLayout";
import { OperationalUnitCard } from "./OperationalUnitCard";
import { useMemo, useState } from "react";
import { OperationalUnitStatus } from "../../store/models/operationalUnit/OperationalUnitStatus";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import { COLOR_GRAY_3, COLOR_WHITE } from "../atoms/ImsMaterialTheme";
import { useTranslation } from "react-i18next";

const OperationalUnitOverview = (props: ThunkProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showInactiveUnits, setShowInactiveUnits] = useState(false);
  const { operationalUnits } = props;
  const rootUnit = operationalUnits?.find((unit) => !unit.parentUnitNumber);
  const { t } = useTranslation();
  const getFilteredInactiveUnits: OperationalUnitDto[] = useMemo(() => {
    return operationalUnits.filter((unit) => showInactiveUnits || unit.unitStatus === OperationalUnitStatus.ENABLED);
  }, [operationalUnits, showInactiveUnits]);
  const handleSwitchChange = () => {
    setShowInactiveUnits(!showInactiveUnits);
  };
  return (
    <MenuLayout headline={t("operationalUnit.pageTitle")}>
      <Grid container>
        {canMaintainOperationalUnits() && (
          <Grid container direction="column" alignItems="flex-end">
            <Button
              component={Link}
              to={UrlPathOperationalUnitCreate}
              startIcon={<Add />}
              variant="contained"
              color="primary"
              style={{ marginBottom: theme.spacing(4) }}
            >
              {t("operationalUnit.buttonCreate")}
            </Button>
          </Grid>
        )}
        <Grid
          container
          style={{
            backgroundColor: COLOR_WHITE,
            borderRadius: theme.spacing(1),
            marginBottom: theme.spacing(2),
            marginTop: theme.spacing(2),
          }}
        >
          <Grid
            item
            md={8}
            style={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              paddingLeft: theme.spacing(3),
            }}
          >
            <Autocomplete
              onChange={(event: any, unit: OperationalUnitDto | null) => {
                if (unit) {
                  navigate(`${UrlPathOperationalUnit}/${unit.unitNumber}/edit`);
                }
                return false;
              }}
              options={operationalUnits}
              getOptionLabel={(option) => option.unitName + " (" + option.externalUnitNumber + ")"}
              renderInput={(params) => <TextField {...params} label={t("operationalUnit.searchForOperationalUnit")} />}
              fullWidth
            />
          </Grid>
          <Grid
            item
            md={4}
            style={{
              marginTop: theme.spacing(2),
              marginBottom: theme.spacing(2),
              paddingRight: theme.spacing(2),
            }}
          >
            <Grid container item justifyContent="flex-end" alignItems="baseline">
              {t("operationalUnit.toggleShowInactive")}
              <Switch color="primary" checked={showInactiveUnits} onChange={handleSwitchChange} />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          item
          md={12}
          style={{
            backgroundColor: COLOR_GRAY_3,
            borderRadius: theme.spacing(1),
          }}
        >
          <Grid
            container
            style={{
              marginTop: theme.spacing(3),
              marginBottom: theme.spacing(2),
              paddingLeft: theme.spacing(6),
            }}
          >
            <Grid item xs={3}>
              <b>{t("operationalUnit.columnOperationalUnit")}</b>
            </Grid>
            <Grid item xs={2}>
              <b>{t("operationalUnit.columnId")}</b>
            </Grid>
            <Grid item xs={2}>
              <b>{t("operationalUnit.columnAddress")}</b>
            </Grid>
            <Grid item xs={5}>
              <b>{t("operationalUnit.columnContact")}</b>
            </Grid>
          </Grid>
          {rootUnit && (
            <TreeView
              defaultExpanded={[rootUnit.externalUnitNumber]}
              defaultCollapseIcon={<KeyboardArrowDown />}
              defaultExpandIcon={<KeyboardArrowRight />}
              disableSelection={true}
            >
              <OperationalUnitCard operationalUnit={rootUnit} operationalUnits={getFilteredInactiveUnits} />
            </TreeView>
          )}
        </Grid>
      </Grid>
    </MenuLayout>
  );
};

const mapStateToProps = (state: IStore) => ({
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(OperationalUnitOverview);
