import { Api } from "../../api/Api";
import { BooleanThunk } from "../IStore";
import { GdprRequestDto } from "../models/gdpr/GdprRequestDto";
import { GdprTemplates } from "../models/gdpr/GdprTemplate";
import { thunkCreateErrorNotification, thunkCreateSuccessNotification } from "./NotificationActions";
import i18n from "i18next";

export const GET_GDPR_TEMPLATES = "GET_GDPR_TEMPLATES";
export const UPDATE_GDPR_TEMPLATES = "UPDATE_GDPR_TEMPLATES";

export const thunkGetGdprTemplates = (): BooleanThunk => async (dispatch) => {
  try {
    const gdprTemplates = await Api.getGdprTemplates();
    dispatch({
      type: GET_GDPR_TEMPLATES,
      payload: gdprTemplates,
    });
    return true;
  } catch (e) {
    dispatch(thunkCreateErrorNotification(i18n.t("messages.gdprActions.error.gdprTemplateLoadFail"), e));
    return false;
  }
};

export const thunkUpdateGdprTemplates =
  (gdprTemplates: GdprTemplates): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.updateGdprTemplates(gdprTemplates);
      dispatch({
        type: UPDATE_GDPR_TEMPLATES,
        payload: gdprTemplates,
      });
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.gdprActions.success.gdprTemplateUpdated")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification(i18n.t("messages.gdprActions.error.gdprTemplateUpdateFail"), e));
      return false;
    }
  };

export const thunkCreateGdprDocument =
  (customerNumber: string, gdprRequestDto: GdprRequestDto): BooleanThunk =>
  async (dispatch) => {
    try {
      await Api.createGdprDocument(customerNumber, gdprRequestDto);
      dispatch(thunkCreateSuccessNotification(i18n.t("messages.gdprActions.success.gdprDocumentCreated")));
      return true;
    } catch (e) {
      dispatch(thunkCreateErrorNotification(i18n.t("messages.gdprActions.error.gdprDocumentCreationFail"), e));
      return false;
    }
  };
