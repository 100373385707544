import { Button, Grid, Paper, useTheme } from "@mui/material";
import { useState } from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { Navigate } from "react-router-dom";
import { bindActionCreators, Dispatch } from "redux";
import { UrlPathMain } from "../../api/url";
import { thunkUpdateBonusXpress } from "../../store/actions/BonusXpressActions";
import { canMaintainBonus } from "../../store/actions/LoginActions";
import { IStore } from "../../store/IStore";
import { BonusXpressDto } from "../../store/models/bonusXpress/BonusXpressDto";
import { BonusXpressRoundingType } from "../../store/models/bonusXpress/BonusXpressRoundingType";
import { BonusXpressStatus } from "../../store/models/bonusXpress/BonusXpressStatus";
import MenuLayout from "../app/MenuLayout";
import { FormInput } from "../atoms/FormInput";
import { FormRadioButton } from "../atoms/FormRadioButton";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { useTranslation } from "react-i18next";

const BonusXpressPage = (props: ThunkProps) => {
  const [redirect, setRedirect] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  const statusOptions = [
    { value: BonusXpressStatus.ENABLED, label: t("bonusXpress.yes") },
    { value: BonusXpressStatus.DISABLED, label: t("bonusXpress.no") },
  ];

  const roundingTypeOptions = [
    { value: BonusXpressRoundingType.UP, label: t("bonusXpress.roundUp") },
    { value: BonusXpressRoundingType.DOWN, label: t("bonusXpress.roundDown") },
    { value: BonusXpressRoundingType.MERCANTILE, label: t("bonusXpress.commercialRounding") },
  ];
  if (redirect) {
    return <Navigate to={UrlPathMain} />;
  }
  if (!props.bonusXpress) return null;
  const readOnly = !canMaintainBonus();
  return (
    <MenuLayout headline={t("bonusXpress.title")}>
      <Form
        onSubmit={(bonusXpress: BonusXpressDto) => {
          props.thunkUpdateBonusXpress(bonusXpress);
        }}
        initialValues={props.bonusXpress}
        render={({ handleSubmit, submitting, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Paper
                style={{
                  padding: theme.spacing(3),
                  marginTop: theme.spacing(6),
                }}
              >
                <ImsPaperHead text={t("bonusXpress.headlineActivate")} />
                <Grid container spacing={4}>
                  <Grid container item>
                    <Grid item md={3}>
                      <FormRadioButton
                        label={t("bonusXpress.labelActivate")}
                        name="bonusXpressStatus"
                        value={values.bonusXpressStatus}
                        options={statusOptions}
                        readOnly={readOnly}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              <Paper
                style={{
                  padding: theme.spacing(3, 3, 6, 3),
                  margin: theme.spacing(6, 0, 0, 0),
                }}
              >
                <ImsPaperHead text={t("bonusXpress.headlineDetails")} />
                <Grid container item spacing={4}>
                  <Grid item md={12}>
                    <b>{t("bonusXpress.labelValuePerPoint")}</b>
                  </Grid>
                  <Grid item md={4}>
                    <FormInput
                      type="number"
                      name="bonusXpressAmount"
                      label={t("bonusXpress.cent")}
                      fullWidth={true}
                      variant="outlined"
                      tooltip={t("bonusXpress.centInfo")}
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <b>{t("bonusXpress.labelPayout")}</b>
                  </Grid>
                  <Grid item md={4}>
                    <FormInput
                      type="number"
                      name="bonusXpressThreshold"
                      label={t("bonusXpress.thresholdPoints")}
                      fullWidth={true}
                      variant="outlined"
                      tooltip={t("bonusXpress.thresholdInfo")}
                      readOnly={readOnly}
                    />
                  </Grid>
                  <Grid item md={12}>
                    <FormRadioButton
                      label={t("bonusXpress.rounding")}
                      name="bonusXpressRoundingType"
                      value={values.bonusXpressRoundingType}
                      options={roundingTypeOptions}
                      readOnly={readOnly}
                    />
                  </Grid>
                </Grid>
              </Paper>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  type="button"
                  onClick={() => setRedirect(true)}
                  variant="contained"
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("bonusXpress.buttonCancel")}
                </Button>
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  disabled={submitting || readOnly}
                  style={{ margin: theme.spacing(2, 0, 2, 2) }}
                >
                  {t("bonusXpress.buttonSave")}
                </Button>
              </Grid>
            </form>
          );
        }}
      />
    </MenuLayout>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ thunkUpdateBonusXpress }, dispatch);

const mapStateToProps = (state: IStore) => ({
  bonusXpress: state.bonusXpress.bonusXpress,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusXpressPage);
