import { InteractionType } from "../customer/CustomerInteractionDto";
import { useTranslation } from "react-i18next";

export enum InteractionProcessingType {
  EARN = "EARN",
  BURN = "BURN",
}

export const interactionProcessingTypeOptions = [
  { value: "", label: "---" },
  { value: InteractionProcessingType.EARN, label: "Earn" },
  { value: InteractionProcessingType.BURN, label: "Burn" },
];

export const useInteractionTypeOptions = () => {
    const { t } = useTranslation();

    return [
      {
        value: "",
        label: t("interactionSearch.placeholderOption"),
      },
      {
        value: InteractionType.PAYMENT_INTERACTION,
        label: t("interactionSearch.purchase"),
      },
      {
        value: InteractionType.REVERSAL_INTERACTION,
        label: t("interactionSearch.cancellation"),
      },
      {
        value: InteractionType.COUPON_INTERACTION,
        label: t("interactionSearch.coupon"),
      },
      {
        value: InteractionType.BONUS_XPRESS_INTERACTION,
        label: t("interactionSearch.bonusXpress"),
      },
      {
        value: InteractionType.BONUS_XPRESS_REVERSAL_INTERACTION,
        label: t("interactionSearch.bonusXpressCancellation"),
      },
      {
        value: InteractionType.CUSTOMER_INTERACTION,
        label: t("interactionSearch.other"),
      },
    ];
  };
