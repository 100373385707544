import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { UserDto, UserRole } from "../../store/models/user/UserDto";
import { ImsHeadCell, ImsTable, ImsTableRow } from "../atoms/ImsTable";
import UserTableMenu from "./UserTableMenu";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import { getCurrentDateTimeAsString } from "../atoms/Utils";
import { getCurrentUsername } from "../../store/actions/LoginActions";
import PrintIcon from "@mui/icons-material/Print";
import { Button } from "@mui/material";
import { LightTooltip } from "../app/LightToolTip";
import { UserStatus } from "./UserStatus";
import { OperationalUnitDto } from "../../store/models/operationalUnit/OperationalUnitDto";
import i18n from "../../i18n";
import { useTranslation } from "react-i18next";

export interface UserRoleName {
  key: UserRole;
  value: string;
}
export const getUserRoleNames = () => {
  const t = i18n.t;
  return [
    { key: UserRole.SYSTEM_ADMINISTRATOR, value: t("user.roles.systemAdmin") },
    { key: UserRole.USER_ADMINISTRATOR, value: t("user.roles.userAdmin") },
    { key: UserRole.PROGRAM_ADMINISTRATOR, value: t("user.roles.programAdmin") },
    { key: UserRole.PROGRAM_ANALYST, value: t("user.roles.programAnalysis") },
    { key: UserRole.CUSTOMER_SERVICE_AGENT, value: t("user.roles.consumerService") },
    { key: UserRole.SALES_PERSON, value: t("user.roles.salesStaff") },
  ];
};

export const findRoleName = (userRole?: String) => {
  return getUserRoleNames().find((userRoleName) => userRoleName.key === userRole)?.value;
};

export const downloadPdf = (data: UserDto[], operationalUnits: OperationalUnitDto[]) => {
  return generatePdfContent(data, operationalUnits).save("admin-users.pdf");
};

export const generatePdfContent = (data: UserDto[], operationalUnits: OperationalUnitDto[]) => {
  const doc = new jsPDF();
  const t = i18n.t;

  // Define table columns and rows
  // const columns = ["Anwendername", "E-Mail", "Filiale", "Rollen", "Status", "Last Login"];
  const columns = [
    t("user.username"),
    t("user.mail"),
    t("user.operationalUnit"),
    t("user.role"),
    t("user.status"),
    t("user.lastActivity"),
  ];
  const rows = data.map((item) => [
    item.userName ?? "-",
    item.mailAddress ?? "-",
    operationalUnits.find((operationalUnit) => operationalUnit.unitNumber === item.unitNumber)?.unitName ?? "-",
    item.userRoles.map((userRole) => findRoleName(userRole)).join("\n"),
    item.status ?? "-",
    item.lastAccess ?? "-",
  ]);

  // Add title
  doc.text(t("user.title"), 15, 10);

  // Add table
  autoTable(doc, {
    head: [columns],
    body: rows,
    startY: 20,
    theme: "striped",
    styles: { fontSize: 10, cellWidth: "wrap" },
    columnStyles: {
      0: { cellWidth: 30 }, // username
      1: { cellWidth: 40 }, // E-Mail
      2: { cellWidth: 25 }, // Filiale
      3: { cellWidth: 45 }, // Rollen
      4: { cellWidth: "auto" }, // Status
      5: { cellWidth: 22 }, // Last Login
    },
    tableWidth: "auto",
    rowPageBreak: "avoid",
    didDrawPage: (data) => {
      // Footer at the bottom of each page
      const pageCount = doc.getNumberOfPages();
      const pageNumber = doc.getCurrentPageInfo().pageNumber;

      const pageHeight = doc.internal.pageSize.height;
      const pageWidth = doc.internal.pageSize.width;
      doc.setFontSize(10);

      // Left footer: Generated by user and date
      doc.text(
        t("user.requestedByAt", { username: getCurrentUsername(), time: getCurrentDateTimeAsString() }),
        data.settings.margin.left,
        pageHeight - 10
      );

      // Right footer: Page number

      doc.text(
        t("user.pageOf", { current: pageNumber, total: pageCount }),
        pageWidth - data.settings.margin.right - 20,
        pageHeight - 10
      );
    },
  });

  // Save the PDF
  return doc;
};

const UserTable = (props: ThunkProps) => {
  const { users, operationalUnits } = props;
  const { t } = useTranslation();
  const headCells: ImsHeadCell<UserDto>[] = [
    { val: (val) => val.userName, node: t("user.username") },
    { val: (val) => val.mailAddress, node: t("user.mail") },
    { val: (val) => val.unitNumber, node: t("user.operationalUnit") },
    { val: (val) => val.userRoles, node: t("user.role") },
    { val: (val) => val.status, node: t("user.status") },
    { val: (val) => val.lastAccess, node: t("user.lastActivity") },
  ];

  const rows: ImsTableRow<UserDto>[] = users.map((row) => ({
    value: row,
    nodes: [
      <b>{row.userName}</b>,
      row.mailAddress,
      operationalUnits.find((operationalUnit) => operationalUnit.unitNumber === row.unitNumber)?.unitName,
      row.userRoles.map((userRole) => <div key={userRole}>{findRoleName(userRole)}</div>),
      row.status === UserStatus.ACTIVE ? t("user.userStatuses.active") : t("user.userStatuses.inactive"),
      row.lastAccess ?? "-",

      <UserTableMenu user={row} />,
    ],
  }));

  return (
    <>
      <LightTooltip title={t("user.printAdminListTooltip")}>
        <Button
          data-testid="download-admin-pdf"
          style={{ float: "right", marginBottom: "10px" }}
          onClick={() => downloadPdf(props.users, props.operationalUnits)}
        >
          <PrintIcon />
        </Button>
      </LightTooltip>
      <ImsTable hasMenu={true} headCells={headCells} rows={rows} />
    </>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({}, dispatch);

const mapStateToProps = (state: IStore) => ({
  users: state.users.users,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(UserTable);
