import { Button, Dialog, DialogContent, DialogTitle, Grid, useTheme } from "@mui/material";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkCreateFlowDetails, thunkUpdateFlowDetails } from "../../store/actions/FlowActions";
import { IStore } from "../../store/IStore";
import { FlowDetailsDto, FlowType, FlowTypeTrans } from "../../store/models/flow/FlowDto";
import { FormInput } from "../atoms/FormInput";
import { FormSelect } from "../atoms/FormSelect";
import { nameof, Options } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";
import {useTranslation} from "react-i18next";

interface FlowDetailsDialogProps {
  flowDetail?: FlowDetailsDto;
  flowType: FlowType;
  flowGroupNumber: string;
  circleOptions: Options[];
  identificationTypeOptions: Options[];
  setFlowDetailsDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const FlowDetailsDialog = ({ setFlowDetailsDialogVisible, ...props }: FlowDetailsDialogProps & ThunkProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const submit = async (flow: FlowDetailsDto) => {
    let success;
    if (flow.flowDetailNumber) {
      success = await props.thunkUpdateFlowDetails({ ...flow }, props.flowGroupNumber);
    } else {
      success = await props.thunkCreateFlowDetails({ ...flow }, props.flowGroupNumber);
    }
    if (success) {
      setFlowDetailsDialogVisible(false);
    }
  };

  if (!props.flowDetail) {
    return null;
  }

  const boolOption: Options[] = [
    { value: "true", label: t("flow.yes") },
    { value: "false", label: t("flow.no") },
  ];

  const directionOption: Options[] = [
    { value: "UPGRADE", label: t("flow.upgrade") },
    { value: "DOWNGRADE", label: t("flow.downgrade") },
  ];

  const initialValues = { ...props.flowDetail };
  return (
    <Dialog onClose={() => setFlowDetailsDialogVisible(false)} open={true}>
      <NotificationList isDialog />
      <DialogTitle>
        <b>Detail Flow</b>
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={submit}
          initialValues={initialValues}
          render={({ handleSubmit, submitting }) => {
            return (
              <form key="AdjustmentForm" onSubmit={handleSubmit}>
                <Grid container item spacing={2}>
                  <Grid container item spacing={2} mt={1}>
                    <Grid item>
                      <FormSelect
                        fullWidth={true}
                        name={nameof<FlowDetailsDto>("flowDirection")}
                        label={t("flow.labelDirection")}
                        options={directionOption}
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item>
                      <FormInput
                        type="number"
                        name={nameof<FlowDetailsDto>("threshold")}
                        label={t("flow.labelTreshold")}
                        fullWidth={true}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item>
                      <FormSelect
                        fullWidth={true}
                        name={nameof<FlowDetailsDto>("origin")}
                        label={"Start " + FlowTypeTrans[props.flowType]}
                        options={
                          props.flowType === FlowType.CIRCLE ? props.circleOptions : props.identificationTypeOptions
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item>
                      <FormSelect
                        fullWidth={true}
                        name={nameof<FlowDetailsDto>("target")}
                        label={t("flow.labelTarget") + FlowTypeTrans[props.flowType]}
                        options={
                          props.flowType === FlowType.CIRCLE ? props.circleOptions : props.identificationTypeOptions
                        }
                      />
                    </Grid>
                  </Grid>
                  {props.flowType === FlowType.CIRCLE ? (
                    <Grid container item spacing={2}>
                      <Grid item>
                        <FormSelect
                          fullWidth={true}
                          name={nameof<FlowDetailsDto>("immediate")}
                          label={t("flow.labelImmediately")}
                          options={boolOption}
                        />
                      </Grid>
                    </Grid>
                  ) : null}
                </Grid>
                <Grid
                  container
                  spacing={2}
                  justifyContent="flex-end"
                  style={{
                    paddingTop: theme.spacing(4),
                  }}
                >
                  <Grid item>
                    <Button
                      color="secondary"
                      type="button"
                      variant="contained"
                      onClick={() => setFlowDetailsDialogVisible(false)}
                    >
                      {t("flow.buttonBack")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained" disabled={submitting}>
                      {t("flow.buttonSaveFlow")}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkUpdateFlowDetails, thunkCreateFlowDetails }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(FlowDetailsDialog);
