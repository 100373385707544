import { Brightness1 } from "@mui/icons-material";
import { Grid, Paper, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { bindActionCreators, Dispatch } from "redux";
import { IStore } from "../../store/IStore";
import { IdentificationStatus } from "../../store/models/identification/IdentificationStatus";
import { DateTag } from "../atoms/DateTag";
import ImsEmbeddedTable, { ImsHeadCell, ImsTableRow } from "../atoms/ImsEmbeddedTable";
import { COLOR_GRAY_4, KNISTR_GREEN, KNISTR_RED } from "../atoms/ImsMaterialTheme";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { useTranslation } from "react-i18next";

const Identifications = (props: ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { identifications, identificationTypes, customer } = props;

  if (customer?.externalCustomerId && !identifications?.length) {
    return null;
  }

  const getIdentificationTypeName = (externalIdentificationTypeNumber: string): string | undefined =>
    identificationTypes?.find(
      (identificationType) => identificationType.externalIdentificationTypeNumber === externalIdentificationTypeNumber
    )?.identificationTypeName;

  const headCells: ImsHeadCell[] = [
    {
      title: t("customerCareDetailsPage.passHeadingStatus"),
      align: "center",
    },
    {
      title: t("customerCareDetailsPage.passHeadingPassId"),
      align: "center",
    },
    {
      title: t("customerCareDetailsPage.passHeadingType"),
      align: "center",
    },
    {
      title: t("customerCareDetailsPage.passHeadingValidFrom"),
      align: "center",
    },
  ];

  identifications.sort((a, b) => a.externalIdentificationTypeNumber.localeCompare(b.externalIdentificationTypeNumber));
  const rows: ImsTableRow[] = identifications.map((identification, index) => ({
    key: index,
    value: identification,
    nodes: [
      <Brightness1
        style={{
          fill: getIdentificationStatusColor(identification.status),
          marginRight: theme.spacing(1),
          paddingBottom: 2,
        }}
      />,
      identification.externalIdentificationNumber,
      getIdentificationTypeName(identification.externalIdentificationTypeNumber),
      <DateTag date={identification.validFrom} />,
    ],
  }));

  return (
    <Grid item md={12}>
      <Paper
        style={{
          padding: theme.spacing(3, 0, 3, 0),
          margin: theme.spacing(6, 0, 0, 0),
        }}
      >
        <ImsPaperHead text={t("customerCareDetailsPage.passHeadline")} style={{ marginLeft: theme.spacing(3) }} />
        <Grid
          item
          md={12}
          style={{
            padding: theme.spacing(0),
          }}
        >
          <ImsEmbeddedTable headCells={headCells} rows={rows} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export function getIdentificationStatusColor(status: IdentificationStatus): string {
  switch (status) {
    case IdentificationStatus.ACTIVE:
      return KNISTR_GREEN;
    case IdentificationStatus.INACTIVE:
      return KNISTR_RED;
    default:
      return COLOR_GRAY_4;
  }
}

const mapStateToProps = (state: IStore) => ({
  identifications: state.identifications?.identifications,
  identificationTypes: state.identifications?.identificationTypes,
  customer: state.customerCare.customer,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Identifications);
