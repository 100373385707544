import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, useTheme } from "@mui/material";
import React from "react";
import { Form } from "react-final-form";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkCreateGdprDocument } from "../../store/actions/GdprActions";
import { IStore } from "../../store/IStore";
import { GdprRequestDto } from "../../store/models/gdpr/GdprRequestDto";
import { FormCheckbox } from "../atoms/FormCheckbox";
import { FormDate } from "../atoms/FormDate";
import { nameof, ValidationErrors } from "../atoms/Utils";
import NotificationList from "../notification/NotificationList";
import { useTranslation } from "react-i18next";

interface GdprDialogProps {
  setGdprDialogVisible: React.Dispatch<React.SetStateAction<boolean>>;
}

const GdprDialog = (props: GdprDialogProps & ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setGdprDialogVisible, customer, operationalUnits } = props;
  const submitGdprRequest = async (gdpr: GdprRequestDto) => {
    if (!gdpr.hasWrittenRequest) {
      delete gdpr.writtenDate;
      delete gdpr.entryDate;
    }
    customer && customer.customerNumber && (await props.thunkCreateGdprDocument(customer.customerNumber, gdpr));
    setGdprDialogVisible(false);
  };

  const rootOperationalUnit = operationalUnits.find((operationalUnit) => !operationalUnit.parentUnitNumber)!;

  const defaultGdprRequest: GdprRequestDto = {
    hasWrittenRequest: false,
    sender:
      rootOperationalUnit.unitName +
      "\n" +
      rootOperationalUnit.contact?.address?.streetName +
      " " +
      rootOperationalUnit.contact?.address?.houseNumber +
      "\n" +
      rootOperationalUnit.contact?.address?.zipCode +
      " " +
      rootOperationalUnit.contact?.address?.cityName,
  };

  if (!customer) return null;

  const validateForm = (values: GdprRequestDto) => {
    const errors: ValidationErrors<GdprRequestDto> = {};

    if (values.hasWrittenRequest && !values.writtenDate) {
      errors.writtenDate = "Das Datum des Anschreibens ist ein Pflichtfeld";
    }

    if (values.hasWrittenRequest && !values.entryDate) {
      errors.entryDate = "Das Eingangsdatum ist ein Pflichtfeld";
    }

    if (values.writtenDate && values.entryDate && values.entryDate < values.writtenDate) {
      errors.entryDate = "Das Eingangsdatum darf nicht vor dem Datum des Anschreibens liegen";
    }
    return errors;
  };
  return (
    <Dialog onClose={() => setGdprDialogVisible(false)} open={true}>
      <NotificationList isDialog />
      <Form
        onSubmit={submitGdprRequest}
        initialValues={defaultGdprRequest}
        validate={validateForm}
        render={({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>{t("gdprDialog.title")}</DialogTitle>
            <Divider variant="fullWidth" orientation="horizontal" />
            <DialogContent style={{ padding: theme.spacing(3) }}>
              <Grid item>
                <FormCheckbox
                  label={t("gdprDialog.labelHasWrittenRequest")}
                  name={nameof<GdprRequestDto>("hasWrittenRequest")}
                />
              </Grid>
              <Grid item>
                <FormDate
                  name={nameof<GdprRequestDto>("writtenDate")}
                  label={t("gdprDialog.labelWrittenDate")}
                  readOnly={!values.hasWrittenRequest}
                />
              </Grid>
              <Grid item>
                <FormDate
                  name={nameof<GdprRequestDto>("entryDate")}
                  label={t("gdprDialog.labelEntryDate")}
                  readOnly={!values.hasWrittenRequest}
                />
              </Grid>
            </DialogContent>
            <Divider variant="fullWidth" orientation="horizontal" />
            <DialogActions style={{ padding: theme.spacing(3) }}>
              <Grid container justifyContent="flex-end">
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => setGdprDialogVisible(false)}
                  style={{ marginRight: theme.spacing(3) }}
                >
                  {t("common.cancel")}
                </Button>
                <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                  {t("common.buttonDone")}
                </Button>
              </Grid>
            </DialogActions>
          </form>
        )}
      />
    </Dialog>
  );
};

const mapStateToProps = (state: IStore) => ({
  customer: state.customerCare.customer,
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators(
    {
      thunkCreateGdprDocument,
    },
    dispatch
  );

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(GdprDialog);
