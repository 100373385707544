import { Button, Grid, Paper, Switch, useTheme } from "@mui/material";
import { Field, Form } from "react-final-form";
import { FormInput } from "../atoms/FormInput";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { nameof } from "../atoms/Utils";
import { Search } from "@mui/icons-material";
import { FormSelect } from "../atoms/FormSelect";
import { IStore } from "../../store/IStore";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkInteractionSearch } from "../../store/actions/InteractionActions";
import { getOperationalUnitOptions } from "../operationalUnit/OperationalUnitForm";
import { BonusSearchCondition } from "./BonusRulePage";
import { useTranslation } from "react-i18next";

interface BonusSearchProps {
  setSearchCondition: React.Dispatch<React.SetStateAction<BonusSearchCondition>>;
}

const BonusSearch = (props: BonusSearchProps & ThunkProps) => {
  const theme = useTheme();
  const { operationalUnits, setSearchCondition } = props;

  const operationalUnitOptions = getOperationalUnitOptions(undefined, operationalUnits, theme, false, false);

  const onSubmit = (bonusRule: BonusSearchCondition) => {
    //If root OU is selected => simplify the condition by removing the unitNumbers from search condition
    let parentUnitNumber = operationalUnits.find((ou) => !ou.parentUnitNumber)?.unitNumber ?? "";
    if (parentUnitNumber && bonusRule.unitNumbers) {
      if (bonusRule.unitNumbers.includes(parentUnitNumber)) {
        bonusRule.unitNumbers = [];
      }
    }

    setSearchCondition({
      name: bonusRule.name,
      isRunning: bonusRule.isRunning,
      showInactive: bonusRule.showInactive,
      unitNumbers: bonusRule.unitNumbers,
    });
  };

  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        name: "",
        unitNumbers: [],
        isRunning: false,
        showInactive: false,
      }}
      render={({ handleSubmit, submitting }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Paper
              style={{
                padding: theme.spacing(3, 3, 6, 3),
                margin: theme.spacing(6, 0, 3, 0),
              }}
            >
              <ImsPaperHead text={t("bonusRuleSearch.title")} />
              <Grid container spacing={4}>
                <Grid container spacing={2} item>
                  <Grid container spacing={2} item>
                    <Grid item sm={12}>
                      <b>{t("bonusRuleSearch.name")}</b>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <FormInput
                        type="text"
                        fullWidth={true}
                        name={nameof<BonusSearchCondition>("name")}
                        label={t("bonusRuleSearch.placeHolderName")}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <b>{t("bonusRuleSearch.operationalUnit")}</b>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4}>
                      <FormSelect
                        label={t("bonusRuleSearch.placeHolderOperationalUnit")}
                        name={nameof<BonusSearchCondition>("unitNumbers")}
                        multiple
                        hierarchyLogic
                        options={operationalUnitOptions}
                        fullWidth={true}
                      />
                    </Grid>
                    <Grid container item alignItems="baseline">
                      <b>{t("bonusRuleSearch.currentlyRunningRules")}</b>
                      <Field name={nameof<BonusSearchCondition>("isRunning")} type="checkbox">
                        {({ input }) => {
                          return <Switch color="primary" {...input} />;
                        }}
                      </Field>
                    </Grid>
                    <Grid container item alignItems="baseline">
                      <b>{t("bonusRuleSearch.inactiveRules")}</b>
                      <Field name={nameof<BonusSearchCondition>("showInactive")} type="checkbox">
                        {({ input }) => {
                          return <Switch color="primary" {...input} />;
                        }}
                      </Field>
                    </Grid>

                    <Grid item md={5} />
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
            <Grid container style={{ marginBottom: theme.spacing(3) }} justifyContent="flex-end">
              <Button color="primary" type="submit" variant="contained" disabled={submitting}>
                <Search />
                {t("bonusRuleSearch.buttonSearch")}
              </Button>
            </Grid>
          </form>
        );
      }}
    />
  );
};

const mapStateToProps = (state: IStore) => ({
  operationalUnits: state.operationalUnits.operationalUnits,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => bindActionCreators({ thunkInteractionSearch }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(BonusSearch);
