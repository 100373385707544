import { Divider, Grid, Paper, Stack, useTheme } from "@mui/material";
import { connect, ConnectedProps } from "react-redux";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { thunkGetFamilyByCustomerNumber } from "../../store/actions/FamilyActions";
import { IStore } from "../../store/IStore";
import ImsPaperHead from "../atoms/ImsPaperHead";
import { Api } from "../../api/Api";
import { RewardsDto } from "../../store/models/rewards/RewardsDto";
import {
  formatEuroOrDefault,
  formatGermanDate,
  formatNumberOrDefault,
  isBlankOrEmpty,
  useMountEffect,
} from "../atoms/Utils";
import { FC, ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";

async function getRewardsBy(customerNumber?: string): Promise<RewardsDto> {
  if (!customerNumber) return {};

  return await Api.getRewards(customerNumber)
    .then((rewardsDto) => {
      return rewardsDto;
    })
    .catch((_) => {
      return {};
    });
}

const Rewards = (props: ThunkProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [rewards, setRewards] = useState<RewardsDto>({});

  useMountEffect(() => {
    getRewardsBy(props.customerNumber).then((rewards) => setRewards(rewards));

    return () => {};
  });

  const hasCashback = (): boolean => {
    return (
      !rewards ||
      !isBlankOrEmpty(rewards.currentCollectionIntervalCashbackAmount) ||
      !isBlankOrEmpty(rewards.lastCollectionIntervalCashbackAmount)
    );
  };

  if (!hasCashback()) return null;

  return (
    <Grid height={"100%"}>
      <Paper
        style={{
          padding: theme.spacing(3),
          height: "100%",
        }}
      >
        <ImsPaperHead text={t("customerCareDetailsPage.rewardHeadline")} />
        <Grid container style={{ height: "100%" }}>
          <Stack divider={<Divider orientation="horizontal" flexItem />} width={"100%"}>
            <CollectionIntervalReward
              title={t("customerCareDetailsPage.rewardLabelExpected")}
              intervalStart={rewards.currentCollectionIntervalStart}
              intervalEnd={rewards.currentCollectionIntervalEnd}
              bonusPoint={rewards.currentCollectionIntervalBonusPoint}
              cashbackAmount={rewards.currentCollectionIntervalCashbackAmount}
              totalRevenue={rewards.currentCollectionIntervalTotalRevenue}
            />
            <CollectionIntervalReward
              title={t("customerCareDetailsPage.rewardLabelLastPaid")}
              intervalStart={rewards.lastCollectionIntervalStart}
              intervalEnd={rewards.lastCollectionIntervalEnd}
              bonusPoint={rewards.lastCollectionIntervalBonusPoint}
              cashbackAmount={rewards.lastCollectionIntervalCashbackAmount}
              totalRevenue={rewards.lastCollectionIntervalTotalRevenue}
            />
          </Stack>
        </Grid>
      </Paper>
    </Grid>
  );
};

type CollectionIntervalRewardProps = {
  title: string;
  intervalStart?: string;
  intervalEnd?: string;
  cashbackAmount?: string;
  bonusPoint?: string;
  totalRevenue?: string;
};

const CollectionIntervalReward: FC<CollectionIntervalRewardProps> = ({
  title,
  intervalStart,
  intervalEnd,
  cashbackAmount,
  bonusPoint,
  totalRevenue,
}) => {
  const { t } = useTranslation();
  return (
    <Grid container>
      <Grid item xs={12}>
        <CollectionIntervalTitle>{title}</CollectionIntervalTitle>
      </Grid>
      <Grid item sm={3}>
        <PropertyTitle>{t("customerCareDetailsPage.rewardLabelCollectionInterval")}</PropertyTitle>
        <Date>
          {intervalStart ? (
            <div>
              <div>
                {formatGermanDate(intervalStart)}
                {" - "}
              </div>
              <div>{formatGermanDate(intervalEnd)}</div>
            </div>
          ) : (
            "-"
          )}
        </Date>
      </Grid>
      <Grid item sm={3}>
        <PropertyTitle>{t("customerCareDetailsPage.rewardLabelCashbackAmount")}</PropertyTitle>
        <Number>{formatEuroOrDefault(cashbackAmount)}</Number>
      </Grid>
      <Grid item sm={3}>
        <PropertyTitle>{t("customerCareDetailsPage.rewardLabelBonusPoints")}</PropertyTitle>
        <Number>{formatNumberOrDefault(bonusPoint)}</Number>
      </Grid>
      <Grid item sm={3}>
        <PropertyTitle>{t("customerCareDetailsPage.rewardLabelTotalRevenue")}</PropertyTitle>
        <Number>{formatEuroOrDefault(totalRevenue)}</Number>
      </Grid>
    </Grid>
  );
};
const CollectionIntervalTitle: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div>
      <h2>{children}</h2>
    </div>
  );
};

const PropertyTitle: FC<{ children?: ReactNode }> = ({ children }) => {
  return (
    <div>
      <b>{children}</b>
    </div>
  );
};

const Date: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div style={{ fontSize: 32 }}>{children}</div>;
};

const Number: FC<{ children?: ReactNode }> = ({ children }) => {
  return <div style={{ fontSize: 42 }}>{children}</div>;
};

const mapStateToProps = (state: IStore) => ({
  customerNumber: state.customerCare.customer?.customerNumber,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) =>
  bindActionCreators({ thunkGetFamilyByCustomerNumber }, dispatch);

const connector = connect(mapStateToProps, mapDispatchToProps);
type ThunkProps = ConnectedProps<typeof connector>;
export default connector(Rewards);
